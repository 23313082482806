document.addEventListener 'turbolinks:load', () ->
  return if !!localStorage.getItem("usage_policy_popup")

  message = $('script[data-usage-policy-popup-text]').data('usage-policy-popup-text')
  return unless !!message

  Vue.toasted['show'](message, {
    theme: 'outline'
    position: 'bottom-right'
    containerClass: 'usage-policy-popup-container'
    className: 'usage-policy-popup'
    closeOnSwipe: false
    action: {
      text: 'Принимаю',
      class: ['button', 'button--brand'],
      onClick: (e, toastObject) ->
        localStorage.setItem("usage_policy_popup", 1)
        toastObject.goAway(0)
    }
  })
  $c = $('body').find('.usage-policy-popup-container')
  if $c.length > 0
    $c.find('.button--brand').removeClass('action riple')
    $c.attr('style', 'display: flex !important')